import React, {
  useEffect,
  useState,
  useCallback
} from 'react'
import styled from 'styled-components'
import {
  typography,
  VW,
  colors,
  icons
} from '../../styles'
import RichText from '../RichText'
import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger';

const HowToUseIt = ({
  tab: {
    content: {
      json: {
        content
      }
    }
  }
}) => {
  const [position, setPosition] = useState()

  useEffect(() => {
      gsap.registerPlugin(ScrollTrigger)

      content.map((_, i) => {
        const id = `#point-${i}`
        
        gsap.to(id, {
          scrollTrigger: {
            trigger: id,
            start: `top ${i === 0 ? 'bottom' : 'center'}`,
            end: `bottom ${i + 1 === content.length ? 'top' : 'center'}`,
            toggleActions: 'restart reverse restart reverse',
            onEnter: () => { handleOnEnter(i) },
            onEnterBack: () => { handleOnEnter(i) }
          },
          color: 'black',
          duration: 0.3
        })
      })
  }, [])

  const handleOnEnter = (i) => {
    setPosition(i)
  }

  return (
    <HowToUseIt_wrapper>
      {content.map((point, i) => (
        <Point
          key={`how-point-${i}`}
          id={`point-${i}`}
          isFirst={i === 0}
          isLast={i + 1 === content.length}
          isOpacity={i > position}
          selected={i === position} >
            {icons.how_star}
          <RichText json={point} />
        </Point>
      ))}
    </HowToUseIt_wrapper>
  )
}

const HowToUseIt_wrapper = styled.div`
  padding-left: 30px;

  @media (min-width: 1300px) {
    padding-left: 0;
  }
`

const POINT_SPACING = '20px'
const POINT_SPACING_MOBILE = '10px'

const Point = styled.div`
  position: relative;
  ${typography.body.small}
  color: ${colors.darkGrey};
  max-width: 445px;
  padding-top: ${props => props.isFirst ? '0' : POINT_SPACING_MOBILE};
  padding-bottom: ${props => props.isLast ? '0' : POINT_SPACING_MOBILE};
  opacity: ${props => props.isOpacity ? '0.2' : '1'};
  transition: color 0.3s ease, opacity 0.3s ease;

  &:last-child {
    margin: 0;
  }

  p {
    margin: 0;
  }

  svg {
    position: absolute;
    top: ${props => props.isFirst ? '0' : POINT_SPACING_MOBILE};
    right: calc(100% + 5px);
    opacity: ${props => props.selected ? '1' : '0'};
    transition: opacity 0.3s ease;
  }

  @media (min-width: ${VW.desktop}) {
    ${typography.body.big}
    padding-top: ${props => props.isFirst ? '0' : POINT_SPACING};
    padding-bottom: ${props => props.isLast ? '0' : POINT_SPACING};

    svg {
      top: ${props => props.isFirst ? '0' : POINT_SPACING};
    }
  }
  
  @media (min-width: ${VW.desktop}) and (max-width: 1200px) {
    ${typography.body.small}
  }
`

export default HowToUseIt