import React, { useEffect, useRef } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styled from 'styled-components'

const ItemCarousel = ({
  children,
  updateSlickRef
}) => {
  const slick_ref = useRef()

  const settings = {
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    fade: true
  }

  useEffect(() => {
    updateSlickRef(slick_ref.current)
  }, [slick_ref])
  
  return (
    <StyledCarousel>
      <Slider
        {...settings}
        ref={slick_ref} >
        {children}
      </Slider>
    </StyledCarousel>
  )
}

const StyledCarousel = styled.div`
  .slick-arrow {
    display: none !important;
  }

  .slick-slide {
    pointer-events: none;

    &.slick-current {
      pointer-events: initial;
    }
  }
`

export default ItemCarousel