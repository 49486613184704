import { useState, useEffect } from 'react'
import useShopifyProduct from '../hooks/useShopifyProduct'

const Price = ({ shopifyProduct }) => {
  const staticPrice = shopifyProduct.variants.edges[0].node.price
  const { product } = useShopifyProduct(shopifyProduct)

  const [price, setPrice] = useState(staticPrice)
  useEffect(() => {
    if (product && !product.loading) {
      const dynamicPrice =
        product.variants.edges[0].node.presentmentPrices.edges[0].node.price
      if (dynamicPrice.amount !== staticPrice) {
        setPrice(dynamicPrice.amount)
      }
    }
  }, [product, staticPrice])

  return `$${Math.round(price * 100) / 100}`
}

export default Price
