import React, { useLayoutEffect, useRef } from 'react'
import Select from 'react-select'
import styled from 'styled-components'

const VariantSelector = ({
  title,
  options,
  updateSelectedOption
}) => {
  const options_ref = useRef()

  useLayoutEffect(() => {
    options_ref.current = options.map(value => ({
      label: value,
      value
    }))
  }, [options])

  const handleSizeSelect = ({ value }) => {
    updateSelectedOption({
      [title]: value,
    })
  }
  
  return (
    <Selector>
      <Select
        options={options_ref.current}
        onChange={handleSizeSelect}
        isSearchable={false}
        placeholder={`Select a ${title}`} />
    </Selector>
  )
}

const Selector = styled.div`
  [class*="menu"],
  [class*="control"] {
    * {
      cursor: pointer;
    }
  }

  [class*="control"] {
    border-color: black !important;
    border-radius: initial;
    box-shadow : initial;
  }

  [class*="indicatorSeparator"] {
    display: none;
  }

  [class*="placeholder"] {
    color: black;
  }

  svg {
    fill: #000;
  }
`

export default VariantSelector