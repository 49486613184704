import React, {
  useCallback,
  useState
} from 'react'
import styled from 'styled-components'
import {
  typography,
  VW
} from '../../styles'
import RichText from '../RichText'
import Accordions from '../shared/Accordions'

const WhatsIncluded = ({
  tab: {
    intro,
    accordions
  }
}) => {
  return (
    <WhatsIncluded_wrapper>
      <Accordions
        type='chunky'
        title={intro}
        items={accordions} />
    </WhatsIncluded_wrapper>
  )
}

const WhatsIncluded_wrapper = styled.div`
  @media (min-width: ${VW.desktop}) and (max-width: 1200px) {
    '[class*="Styled_Accordion"]' {
      '[class*="Title"]' {
        ${typography.body.regular}
      }

      '[class*="Description_wrapper"]' {
        ${typography.body.tiny}
      }
    }
  }
`

export default WhatsIncluded