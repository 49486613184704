import React, { useContext } from 'react'
import styled from 'styled-components'
import {
  colors,
  VW,
  typography,
  sectionGrid,
  font,
  VARIABLE_FUNCTION
} from '../../styles'
import RichText from '../RichText'
import HeroCarousel from '../shared/HeroCarousel'
import Accordions from '../shared/Accordions'
import AddToCart from '../product/AddToCart'
import { LayoutContext } from '../../contexts'

const Showcase = ({
  title,
  subtitle,
  volume,
  accordions,
  carouselItems,
  shopifyProduct
}) => {
  const { headerBanner } = useContext(LayoutContext)

  return (
    <StyledShowcase_wrapper headerBanner={headerBanner}>
      <StyledShowcase_container>
        <Content>
          <Title>{title}</Title>
          <Subtitle>
            <RichText json={subtitle} />
          </Subtitle>
          {volume && <Volume>{volume}</Volume>}
          {accordions && (
            <Accordions
              items={accordions}
              expandFirst
              noAccordion={title === `Full Regimen`} />
          )}
          {shopifyProduct && <AddToCart shopifyProduct={shopifyProduct} />}
        </Content>
        <Carousel_wrapper>
          <Carousel_container headerBanner={headerBanner}>
            <HeroCarousel items={carouselItems} />
          </Carousel_container>
        </Carousel_wrapper>
      </StyledShowcase_container>
    </StyledShowcase_wrapper>
  )
}

const topSpacing = 110
const bottomSpacing = 135

const StyledShowcase_wrapper = styled.div`
  background-color: ${colors.lightGrey};
  margin-top: ${props => VARIABLE_FUNCTION(props.headerBanner).mobileHeader};
  padding-bottom: 30px;

  @media (min-width: ${VW.desktop}) {
    margin-top: ${props => VARIABLE_FUNCTION(props.headerBanner).desktopHeader};
    padding-bottom: 60px;
  }
`

const StyledShowcase_container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 20px 20px 0;

  @media (min-width: ${VW.desktop}) {
    ${sectionGrid}
    padding: 0 20px;
  }
`

const Carousel_wrapper = styled.div`  
  position: relative;
  height: calc(100% - 65px);

  [class*="StyledCarousel"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: initial;

    .slick-dots {
      top: calc(100% + 5px);
      left: 50%;
      transform: translateX(-50%);
  
      li {
        button::before {
          color: black;
        }
      }
    }
  }

  @media (min-width: ${VW.desktop}) {
    grid-column-start: 7 !important;
    grid-column-end: 13 !important;
  }

  @media (min-width: 1300px) {
    [class*="StyledCarousel"] .slick-dots {
      top: 50%;
      left: calc(50% - 21px);
      transform: rotate(90deg) translateY(-50px);
      bottom: initial;
    }
  }
`

const Carousel_container = styled.div`
  position: sticky;
  width: 100%;
  padding-bottom: calc(100% * 0.9333333333);

  @media (min-width: ${VW.desktop}) {
    top: calc(${props => VARIABLE_FUNCTION(props.headerBanner).desktopHeader} + 65px);
  }
`

const Content = styled.div`
  max-width: 390px;
  width: 100%;
  margin: 65px auto 0;

  .accordion {
    i {
      margin-left: 10px;
    }
  }

  @media (min-width: ${VW.desktop}) {
    grid-column-start: 1 !important;
    grid-column-end: 7 !important;
    margin: ${topSpacing}px 0 ${bottomSpacing}px;
    padding: 0;
  }
`

const Title = styled.h1`
  ${typography.headlines.regular}
  margin-bottom: 10px;

  @media (min-width: ${VW.desktop}) {
    ${typography.headlines.articleAlternate}
  }
`

const Subtitle = styled.div`
  ${typography.body.big}
  max-width: 350px;
  margin-bottom: 60px;
  
  p { margin: 0 }
`

const Volume = styled.span`
  ${typography.body.tiny}
  font-family: ${font.typewriter};
  color: ${colors.darkGrey};
  display: block;
`

export default Showcase