import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { font, colors, typography, VW } from '../../styles'
import RichText from '../RichText'

const errorMessage = {
  name: '*Name is required',
  email: '*Email is required',
  title: '*A minimum of 3 and a maximum of 20 characters is required',
  comment: '*A minimum of 10 and a maximum of 1500 characters is required',
  rating: '*Rating is required',
}

const Review = ({
  reviewSection: {
    quote: pQuote,
    quoteInformation: pQuoteInformation,
    rating: pQuoteRating
  },
  shopifyProduct
}) => {
  const quote = pQuote && pQuote.json
  const quoteInformation = pQuoteInformation && pQuoteInformation.json

  const handleToIdentifier = {
      "face-base-primer-isla": 4617727377453,
      "isla-full-skincare-regimen-isla": 4617740222509,
      "snow-scrub-exfoliator-isla": 4617736060973,
      "tone-balance-elixir-isla": 4617722036269,
      "storm-serum": 6180612374684,
      "mothers-day-gift-set": 6715933917340,
      "the-full-routine": 6715597258908,
      "isla-crewneck": 6741110128796,
      "isla-sweatpants": 6741107769500,
      "face-towel-set": 6913394311324
  }
  const [reviewData, setReviews] = useState(null)
  const [openState, setOpen] = useState(false)
  // const [formData, setFormData] = useState({})
  const [hasInit, setHasInit] = useState()

  const quoteRating = pQuoteRating && Number(pQuoteRating.split(' ')[0])

  useEffect(() => {
    if (typeof window !== 'undefined' && shopifyProduct && shopifyProduct.handle && handleToIdentifier[shopifyProduct.handle]) {
      const reviewUrl = `https://productreviews.shopifycdn.com/proxy/v4/reviews/product?callback=SPRReviewsLoaded&product_id=${handleToIdentifier[shopifyProduct.handle]}&version=v4&shop=isla-us.myshopify.com&_=${(new Date()).getTime()}`
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src = reviewUrl;
      document.getElementsByTagName('head')[0].append(s);

      window.SPRReviewsLoaded = (data) => {
        setReviews(data)
      }
    } else {
      console.error({
        problem: 'This prevents the use of the Review Section.',
        message: `This product needs to be added to the handleToIdentifier object. The value can be found in the url of the Shopify Product.`,
        handle: shopifyProduct.handle
      })
    }
  }, [])

  useEffect(() => {
    if(hasInit || typeof document === 'undefined') { return }

    if(!reviewData) { return }

    setUpReviews()

    const submitButton = document.querySelector('.spr-form-actions input')
    const rating = document.querySelector('.spr-form-review-rating .spr-starrating')

    submitButton.addEventListener('click', (event) => handleFormSubmit(event))

    for (let i = 0; i < rating.children.length; i++) {
      const star = rating.children[i];
      star.addEventListener('click', (element) => handleStarSelect(element, rating))
    }

    for (const key in getFields()) {
      if (getFields().hasOwnProperty(key)) {
        const field = getFields()[key];
        const pTag = document.createElement('P')
        pTag.appendChild(document.createTextNode(errorMessage[key]))
        pTag.classList.add('error-message')
        field.appendChild(pTag)

        let input
        if(key === 'name' || key === 'email' || key === 'title') {
          input = field.querySelector('input')
          input.addEventListener('keyup', (input) => handleInputChange(input))

        } else if(key === 'comment') {
          input = field.querySelector('textarea')
          input.addEventListener('keyup', (input) => handleInputChange(input))

        } else {
          input = field.querySelector('.spr-starrating')
          input.addEventListener('click', () => handleInputChange(input, true))
        }

        if(key === 'name' || key === 'email') {
          input.setAttribute('maxlength', 150)

        } else if(key === 'title') {
          input.setAttribute('maxlength', 20)

        } else if(key === 'comment') {
          input.setAttribute('maxlength', 1500)
        }

        const errorMessageNode = field.children[field.children.length - 1]

        field._errorMessage = errorMessageNode
        field._input = input
        input._field = field
        input._errorMessage = errorMessageNode
      }
    }

    const formSuccess = 'Fabulous! Your review has been submitted!'
    const form = document.querySelector('.review__form')
    const pTag = document.createElement('P')
    pTag.appendChild(document.createTextNode(formSuccess))
    pTag.classList.add('success-message')
    form.appendChild(pTag)

    const buttonMessages = [
      {
        text: 'Something went wrong, please try again later',
        classs: 'server-error'
      },
      {
        text: 'Your email has already been used to submit a review.',
        classs: 'email-error'
      }
    ]

    const formAction = document.querySelector('.spr-form-actions')

    for (let i = 0; i < buttonMessages.length; i++) {
      const {
        text,
        classs
      } = buttonMessages[i];

      const pTag = document.createElement('P')
      pTag.appendChild(document.createTextNode(text))
      pTag.classList.add(classs, 'error-message')
      formAction.appendChild(pTag) 
    }
    
    setHasInit(true)

    return () => {
      submitButton.removeEventListener('click', handleFormSubmit)

      for (let i = 0; i < rating.children.length; i++) {
        const star = rating.children[i];
        star.removeEventListener('click', (element) => handleStarSelect(element, rating))
      }
    }
  }, [reviewData])

  const setUpReviews = () => {
    const reviews = document.querySelectorAll('.spr-review')

    for (let i = 0; i < reviews.length; i++) {
      const review = reviews[i];
      const content = review.querySelector('.spr-review-content')
      const title = review.querySelector('.spr-review-header-title')
      content.prepend(title)
    }
  }

  const handleInputChange = (input, skipTarget) => {
    if(skipTarget) {
      input._errorMessage.classList.remove('error')
      
    } else {
      input.target._errorMessage.classList.remove('error')
    }
  }

  const getFields = () => ({
    name: document.querySelector('.spr-form-contact-name'),
    email: document.querySelector('.spr-form-contact-email'),
    title: document.querySelector('.spr-form-review-title'),
    comment: document.querySelector('.spr-form-review-body'),
    rating: document.querySelector('.spr-form-review-rating')
  })

  const handleFormSubmit = async (event) => {
    event.preventDefault()

    if(typeof document === 'undefined') { return }

    const form = document.querySelector('.review__form')

    form.classList.remove('server-error-form')
    form.classList.remove('email-error-form')

    const height = form.clientHeight
    form.style.maxHeight = `${height}px`;
    
    const {
      name,
      email,
      title,
      comment,
      rating
    } = getFields()

    let failed

    if(3 > name._input.value.length || name._input.value.length > 150) {
      name._errorMessage.classList.add('error')
      failed = true
    } else {
      name._errorMessage.classList.remove('error')
    }

    if(3 > email._input.value.length || email._input.value.length > 150) {
      email._errorMessage.classList.add('error')
      failed = true
    } else {
      email._errorMessage.classList.remove('error')
    }

    if(3 > title._input.value.length || title._input.value.length > 20) {
      title._errorMessage.classList.add('error')
      failed = true
    } else {
      title._errorMessage.classList.remove('error')
    }
    
    if(10 > comment._input.value.length || comment._input.value.length > 1500) {
      comment._errorMessage.classList.add('error')
      failed = true
    } else {
      comment._errorMessage.classList.remove('error')
    }

    if(!rating._input.dataset.value) {
      rating._errorMessage.classList.add('error')
      failed = true
    } else {
      rating._errorMessage.classList.remove('error')
    }

    if(failed) {
      form.style.maxHeight = 'initial'
      return
    }

    const button = document.querySelector('.spr-form-actions input')
    button.disabled = true

    if (window) {
      window.reviewSubmitCallback = (data) => {
        console.log('data', data)
        if (data.status === 'success') {
          form.classList.add('close-form')
        } else {
          form.classList.add('server-error-form')
          button.disabled = false
          const error = (data.form || '').split('<div class="spr-form-message spr-form-message-error">').pop().split('</div>').shift()
          form.querySelector('.server-error').innerHTML = error
        }
      }
    }
    let reviewUrl = `https://productreviews.shopifycdn.com/proxy/v4/reviews/create?callback=reviewSubmitCallback&shop=isla-us.myshopify.com`
    reviewUrl = `${reviewUrl}&review%5Bauthor%5D=${encodeURIComponent(name._input.value)}`
    reviewUrl = `${reviewUrl}&review%5Bemail%5D=${encodeURIComponent(email._input.value)}`
    reviewUrl = `${reviewUrl}&review%5Btitle%5D=${encodeURIComponent(title._input.value)}`
    reviewUrl = `${reviewUrl}&review%5Bbody%5D=${encodeURIComponent(comment._input.value)}`
    reviewUrl = `${reviewUrl}&review%5Brating%5D=${encodeURIComponent(rating._input.dataset.value)}`
    reviewUrl = `${reviewUrl}&product_id=${encodeURIComponent(handleToIdentifier[shopifyProduct.handle])}`
    console.log(reviewUrl)
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.src = reviewUrl;
    document.getElementsByTagName('head')[0].append(s);
  }

  const handleStarSelect = (star, rating) => {
    star.preventDefault()
    const value = star.target.dataset.value
    rating.dataset.value = value

    const ratingScore = Number(value) - 1

    for (let i = 0; i < rating.children.length; i++) {
      const reStar = rating.children[i];
      if(ratingScore >= i) {
        reStar.classList.remove('spr-icon-star-empty')
      } else {
        reStar.classList.add('spr-icon-star-empty')
      }
    }
  }

  const handleOpen = () => {
    setOpen(!openState)
  }

  const typographyBodyTiny = 'font-style: normal; font-size: 15px; line-height: 135%;'

  const scopedStyle = `
  .spr-container {
    padding: 0 !important;
  }
  .spr-icon {
    font-family: ${font.primary};
    width:1em;
    height: 1em;
    background-size: contain;
    background-position: center center;
    display: inline-block;
    background-repeat: no-repeat;
  }
  .spr-icon-star {
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%2020%2019%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M19.977%207.14023h-7.6279L9.98852%200%207.62795%207.14023H0l6.15504%204.36997-2.23376%207.0427%206.06724-4.1066%206.06728%204.1066-2.2338-7.0427%206.155-4.36997z%22%20fill%3D%22%23E32844%22%2F%3E%3C%2Fsvg%3E");
  }
  .spr-icon-star-empty {
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%2021%2019%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M12.1019%207.29718l.1134.34305h6.4214l-4.8766%203.46227-.2974.2112.1102.3477%201.7986%205.6706-4.8752-3.2998-.2802-.1897-.2803.1897-4.8752%203.2998%201.79858-5.6706.11028-.3477-.29743-.2112-4.87656-3.46227h6.42134l.11341-.34305L10.2161%201.5929l1.8858%205.70428z%22%20stroke%3D%22%23E32844%22%2F%3E%3C%2Fsvg%3E");
  }
  .spr-header-title, .spr-summary-actions {
    display: none;
  }
  .spr-header .spr-summary {
    display: flex;
  }
  .spr-summary-starrating {
    order :2;
    margin-left: auto;
    font-size: 18px;
  }
  .spr-summary-caption {
    /* 32 Reviews */
    /* H Basel Book - 30 */
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 120%;
    /* or 36px */

    /* BLACK */
    color: #000000;
  }

  .spr-review {
    display: block;
    border-bottom: 1px solid ${colors.mediumGrey} !important;
    padding: 16px 0 60px;
  }

  .spr-review-header {
    position: relative;
    display: inline-block;
    vertical-align: top;
  }

  .spr-review-header-byline {
    ${typographyBodyTiny}
    display: block;
    width: 90px;
    margin-top: 10px;
  }

  .spr-review-header-byline strong:last-child {
    color: ${colors.darkGrey};
    display: inline-block;
  }

  .spr-review-header-starratings {
    display: block;
    font-size: 14px;
  }
  
  .spr-review-content {
    display: inline-block;
    vertical-align: top;
    max-width: calc(100% - 94px);
  }

  .spr-review-content-body {
    ${typographyBodyTiny}
    margin: 11px 0 0;
    padding-left: 16px;
  }

  .spr-review-header-title {
    ${typographyBodyTiny}
    font-weight: bold;
    padding-left: 16px;
  }

  .spr-review-footer {
    display: none;
  }

  .spr-form-title {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 120%;
    color: #000000;
    font-size: 20px;
  }

  .review__form {
    position: relative;
    margin-top: 35px;
    max-width: 650px;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }

  .server-error-form .server-error,
  .email-error-form .email-error {
    opacity: 1;
    pointer-events: initial;
    margin-bottom: 8px;
  }

  form {
    font-size: 15px;
  }

  .spr-form-contact,
  .spr-form-review,
  .spr-form-actions {
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  .spr-form-contact {
    margin: 30px 0 40px;
  }

  .spr-form-contact > * {
    max-width: 325px;
    border-bottom: 1px solid black;
    width: 100%;
  }

  .spr-form-contact input {
    width: calc(100% - 53px);
    margin-left: 10px;
  }

  .spr-form-contact input::placeholder {
    color: ${colors.mediumGrey};
  }

  .spr-form-contact-name {
    position: relative;
  }

  .spr-form-contact-name > * {
    display: inline-block;
    vertical-align: top;
  }

  .spr-form-contact-email {
    position: relative;
    margin-top: 35px;
  }

  .spr-form-review-title {
    position: relative;
    width: 100%;
    border-bottom: 1px solid black;
    margin: 30px 0 40px;
    max-width: 325px;
  }

  .spr-form-review-title input {
    width: calc(100% - 92px);
    margin-left: 9px;
  }

  .spr-form-review-title input::placeholder {
    color: ${colors.mediumGrey};
  }

  .spr-form-review-body {
    position: relative;
    margin-bottom: 30px;
  }

  .spr-form-review-body label {
    display: block;
    margin-bottom: 3px;
  }

  .spr-form-review-body textarea {
    width: 100%;
    border: none;
    border-radius: 0;
    resize: none;
    padding: 10px 15px;
    box-sizing: border-box;
  }

  .spr-form-review-body textarea::placeholder {
    font-size: 12px;
    color: ${colors.darkGrey};
    font-family: Basel-Typewriter;
  }
  
  @media (min-width:${VW.desktop}) {
    .spr-review {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-column-gap: 20px;
    }

    .spr-review-header {
      grid-column-start: 1;
      grid-column-end: 5;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }

    .spr-review-content {
      grid-column-start: 5;
      grid-column-end: 10;
    }

    .spr-review-header-starratings {
      font-size: 16px;
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .spr-review-header-byline {
      grid-column-start: 3;
      grid-column-end: 5;
      max-width: calc(100% - 20px);
      margin: 0;
    }

    .spr-review-content-body {
      padding: 0;
      margin: 11px 0 0;
    }

    .spr-review-header-byline strong:last-child {
      margin-top: 11px;
    }

    .spr-review-header-title {
      width: 100%;
      padding: 0;
    }

    .spr-summary-starrating {
      font-size: 20px;
    }

    .spr-summary-caption {
      font-size: 30px;
    }

    .spr-form-title {
      font-size: 30px;
    }

    .spr-form-contact > * {
      display: inline-block;
      vertical-align: top;
      max-width: calc(50% - 20px);
    }

    .spr-form-contact-email {
      margin: 0 0 0 40px;
    }

    .spr-form-review-title {      
      width: calc(50% - 20px);
      max-width: initial;
    }

    .spr-form-review-title input {
      width: calc(100% - 92px);
    }
  }
  `

  const noCustomerReviewClass = reviewData &&
    reviewData.product &&
    reviewData.product.indexOf('There are no other reviews') !== -1 ?
    'no-review' : ''

  const hideFakeReviewClass = !quoteRating || !quote || !quoteInformation ?
    'no-fake' : ''

  return (
    <ReviewWrapper
      noFake={!!hideFakeReviewClass}
      noReview={!!noCustomerReviewClass} >
      <div className={`review__inner ${noCustomerReviewClass} ${hideFakeReviewClass}`}>
        {quoteRating && (
          <div className="review__stars">
            {[1, 2, 3, 4, 5].map((_, i) => {
              const addedFill = quoteRating >= i + 1 ?  { fill: colors.red } : {}
              return (
                <React.Fragment key={`ratingStars-${i}`}>
                  <svg width="20" height="15" viewBox="-1 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8657 5.67076H9.80763L7.93287 0L6.05811 5.67076H0L4.88832 9.14139L3.11427 14.7347L7.93287 11.4732L12.7515 14.7347L10.9774 9.14139L15.8657 5.67076Z" {...addedFill} stroke="#E32844"/>
                  </svg>
                </React.Fragment>
            )})}
          </div>
        )}
        {quote && (
          <div className="review__title">
            <div className="review__title_content">
              “
              <RichText json={quote} />
              ”
            </div>
          </div>
        )}
        {quoteInformation && (
          <div className="review__author">
            <RichText json={quoteInformation} />
          </div>
        )}
        <div className="review__separator" />
        <button
          onClick={handleOpen}
          className={`review__toggle ${openState ? 'open' : ''}`} >
          See All Reviews 
        </button>
        <div className="review__content" style={{display: openState ? 'block' : 'none'}}>
          <div dangerouslySetInnerHTML={{__html: `<style>${scopedStyle}</style>`}} />
          <div className="review__header" dangerouslySetInnerHTML={{__html: reviewData ? reviewData.product : ''}} />
          <div className="review__list" dangerouslySetInnerHTML={{__html: reviewData ? reviewData.reviews : ''}} />

          
          <div className="review__form" dangerouslySetInnerHTML={{__html: reviewData ? reviewData.form : ''}} />

        </div>
      </div>
    </ReviewWrapper>
  )
}

const ReviewWrapper = styled.div`
  background: #F9DBCF;
  width: 100%;
  max-width: 1200px;
  margin: 45px auto 0;
  padding: ${props => props.noFake ? '1px' : '40px'} 0 0 0;
  position: relative;
  font-family: ${font.primary};
  .review {
    &__inner {
      margin: 0 auto;

      & > * {
        padding: 0 20px;
      }

      &.no-review {
        .review__toggle,
        .review__header,
        .review__list {
          display: none;
        }

        .review__content {
          display: block !important;
        }
      }

      &.no-fake {
        .review__separator,
        .review__title,
        .review__stars,
        .review__author {
          display: none;
        }
      }

    }
    &__stars {
      text-align: center;
      margin-bottom: 45px;
    }
    &__title {
      ${typography.body.big}
      color: ${colors.red};
      text-align: center;
      max-width: 995px;
      margin: 30px auto 40px;
    }
    &__author {
      text-align: center;
      margin: 0 auto 45px;
      ${typography.body.small};
      color:${colors.red};
      border-style: solid;
    }
    &__separator {
      border: 1px solid ${colors.red};
    }
    &__content {
      max-width: 825px;
      margin: 0 auto;
      margin-top: 58px;
    }
    &__toggle {
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      letter-spacing: -0.02em;
      /* ISLA Red */
      color: ${colors.red};
      text-align: center;
      display: block;
      padding:12px;
      display: table;
      margin: auto;
      white-space: nowrap;
      cursor: pointer;
      &.open:after {
        transform:rotate(0);
      }
      &:after {
        transition: all 0.2s ease-in-out;
        transform:rotate(180deg);
        content:'';
        background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%2011%207%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M10%206L5.5%202%201%206%22%20stroke%3D%22%23E32844%22%20stroke-width%3D%222%22%2F%3E%3C%2Fsvg%3E");
        backgroound-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        width: 12px;
        height:8px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
      }
    }
    .spr-icon-star {
      width:1em;
      height: 1em;
      background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D%220%200%2020%2019%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M19.977%207.14023h-7.6279L9.98852%200%207.62795%207.14023H0l6.15504%204.36997-2.23376%207.0427%206.06724-4.1066%206.06728%204.1066-2.2338-7.0427%206.155-4.36997z%22%20fill%3D%22%23E32844%22%2F%3E%3C%2Fsvg%3E");
      background-size: contain;
      background-position: center center;
      display: inline-block;
    }
    &__header {
      margin:0 auto;
    }
  }

  .spr-form-actions {
    position: relative;

    input {
      white-space: nowrap;
      padding: 5px 20px;
      font-size: 15px;
      color: ${colors.red};
      background-color: transparent;
      border: 1px solid ${colors.red};
      cursor: pointer;
      margin: 0 0 40px;
      transition: color 0.3s ease-in-out,
                  border-color 0.3s ease-in-out,
                  background-color 0.3s ease-in-out;
  
      &:hover {
        color: white;
        background-color: ${colors.red};
      }
  
      // &:disabled {
      //   color: white;
      //   background-color: ${colors.darkGrey};
      //   border-color: ${colors.darkGrey};
      // }
    }
  }

  .close-form {
    max-height: 200px !important;

    .success-message {
      opacity: 1;
      pointer-events: initial;
    }

    .spr-form-contact,
    .spr-form-review,
    .spr-form-actions {
      opacity: 0;
      pointer-events: none;
    }
  }

  .success-message {
    position: absolute;
    top: 32px;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease 0.5s;
    pointer-events: none;
    color: ${colors.red};
    white-space: nowrap;
  }

  .spr-form-review-rating {
    position: relative;

    .spr-form-label,
    .spr-form-input {
      display: inline-block;
      vertical-align: top;
    }

    .spr-form-input {
      margin: 3px 0 0 6px;
    }
  }

  .error-message {
    position: absolute;
    bottom: 100%;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
    color: ${colors.red};
    pointer-events: none;
    margin: 0;
    font-size: 12px;
    white-space: nowrap;

    &.error {
      opacity: 1;
      pointer-events: initial;
    }
  }

  .review__header {
    padding-bottom: 30px;
    border-bottom: 1px solid ${colors.mediumGrey};
  }

  .review__title_content {
    &,
    p {
      display: inline;
    }
  }

  .review__author {
    p {
      margin: 0;
    }
  }

  @media (min-width:${VW.desktop}) {
    width: 90%;
    padding: ${props => props.noFake ? '1px' : '70px'} 0 0 0;
    margin-bottom: 45px;

    .review__title {
      ${typography.headlines.articleAlternate}
      margin: 40px auto;
    }

    .review__author {
      margin: 45px auto;
    }

    .spr-form-actions input {
      margin-bottom: 135px;
    }

    .success-message {
      top: 50px;
    }
  }
`

export default Review