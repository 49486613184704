import React from 'react'
import RichText from '../RichText'
import Button from '../Button'
import styled from 'styled-components'
import {
  typography,
  VW,
  VARIABLE_CONSTANTS
} from '../../styles'

const HeroCenterContent = ({
  text,
  button,
  topLeftText,
  bottomRightText
}) => {

  return (
    <>
      <Copy>
        <p>{topLeftText}</p>
        <p>{bottomRightText}</p>
      </Copy>
      <CenterSection>
      <div className='center-text'>
        <RichText json={text} />
      </div>
      {button && (
        <Button
          title={button.title}
          link={button.urlLink}
          type='small'
          color='white' />
      )}
      </CenterSection>
    </>
  )
}

const Copy = styled.div`
  ${typography.headlines.articleAlternate}
  color: #fff;
  line-height: 0.99;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  p {
    position: absolute;
    margin: 0;

    &:first-child {
      top: calc(40px + ${VARIABLE_CONSTANTS.mobileHeader}px);
      left: 9px;
    }

    &:nth-child(2) {
      bottom: 40px;
      right: 9px;
    }
  }

  @media (min-width: ${VW.desktop}) {
    ${typography.headlines.hero}

    p {
      &:first-child {
        top: calc(40px + ${VARIABLE_CONSTANTS.desktopHeader}px);
        left: 40px;
      }

      &:nth-child(2) {
        right: 40px;
      }
    }
  }
`

const CenterSection = styled.div`
  position: absolute;
  top: calc(${VARIABLE_CONSTANTS.mobileHeader}px / 2 + 50%);
  left: 0;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  height: initial !important;
  padding: 0 20px;
  box-sizing: border-box;

  .center-text {
    margin-bottom: 25px;
    
    p {
      ${typography.body.big}
      color: white;
      margin: 0;
    }
  }

  [class*="Button_trigger_wrapper"] {
    margin-top: ${VARIABLE_CONSTANTS.mobileBlogSpacing};
  }

  @media (min-width: ${VW.desktop}) {
    width: initial;
    top: calc(${VARIABLE_CONSTANTS.desktopHeader}px / 2 + 50%);
    left: 50%;
    transform: translate(-50%, -50%);

    .center-text {
      padding: 0;
    }

    [class*="Button_trigger_wrapper"] {
      margin-top: initial;
    }
  }
`

export default HeroCenterContent