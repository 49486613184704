import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import {
  colors,
  typography,
  VW,
  sectionGrid,
  VARIABLE_FUNCTION
} from '../styles'
import Button from './Button'
import RichText from './RichText'
import Accordions from './shared/Accordions'
import Image from './shared/Image'
import { LayoutContext } from '../contexts'

const QuestionsAndAnswers = ({
  hasShowMore,
  data: {
    intro: {
      json: intro
    },
    qa,
    image,
    emailIntro,
    emailUs,
    emailAddress
  }
}) => {
  const { headerBanner } = useContext(LayoutContext)
  const [isShowMore, setIsShowMore] = useState(false)

  const handleShowMore = () => {
    setIsShowMore(!isShowMore)
  }

  return (
    <QuestionsAndAnswers_wrapper
      hasShowMore={hasShowMore}
      headerBanner={headerBanner} >
      <QuestionsAndAnswers_container hasShowMore={hasShowMore}>
        <Content>
          <Intro>
            <RichText json={intro} />
          </Intro>
          <Accordions
            hasShowMore={hasShowMore}
            items={qa}
            type='box'
            isShowMore={isShowMore} />
            {hasShowMore && (
              <Button_wrapper>
                <Button
                  title={isShowMore ? 'Show Less' : 'See All'}
                  type='small'
                  color='red'
                  triggerClick={handleShowMore} />
              </Button_wrapper>
            )}
          <div className="mobile-email">
            <EmailIntro>{emailIntro}</EmailIntro>
            <EmailUs href={`mailto:${emailAddress}`}>
              {emailUs}
            </EmailUs>
          </div>
        </Content>
        <ImageSection headerBanner={headerBanner}>
          <StyledImage_wrapper>
            <StyledImage
              fluid={image.fluid}
              alt={image.title} />
          </StyledImage_wrapper>
          <div className="desktop-email">
            <EmailIntro>{emailIntro}</EmailIntro>
            <EmailUs href={`mailto:${emailAddress}`}>
              {emailUs}
            </EmailUs>
          </div>
        </ImageSection>
      </QuestionsAndAnswers_container>
    </QuestionsAndAnswers_wrapper>
  )
}

const QuestionsAndAnswers_wrapper = styled.div`
  ${sectionGrid}
  padding: 0 20px;

  @media (min-width: ${VW.desktop}) {
    padding: ${props => VARIABLE_FUNCTION(props.headerBanner).desktopHeader} 20px 0;
  }
`

const QuestionsAndAnswers_container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin: 20px 0 60px;

  .desktop-email {
    display: none;
  }

  .mobile-email {
    margin-top: 65px;
    ${props => props.hasShowMore && `margin-top: 20px;`}
  }

  @media (min-width: ${VW.desktop}) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    margin: 60px 0 120px;

    .desktop-email {
      display: block;
    }

    .mobile-email {
      display: none;
    }
  }
`

const Content = styled.div`
  grid-column-start: 1;
  grid-column-end: 7;
`

const Intro = styled.div`
  ${typography.headlines.regular}
  margin-bottom: 30px;

  p {
    margin: 0;
  }

  b {
    color: ${colors.red}
  }

  @media (min-width: ${VW.desktop}) {
    margin-bottom: 70px;
  }
`

const ImageSection = styled.div`
  grid-column-start: 8;
  grid-column-end: 13;
  margin-left: 75px;
  margin-bottom: 20px;
  max-height: calc(100vh - ${props => VARIABLE_FUNCTION(props.headerBanner).mobileHeader} - 200px);
  overflow: hidden;

  @media (min-width: ${VW.desktop}) {
    margin: 0;
    max-height: initial;
    overflow: initial;
  }
`

const StyledImage_wrapper = styled.div`
  position: relative;
  padding-bottom: 120%;
`

const StyledImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Button_wrapper = styled.div`
  display: inline-block;
  margin-top: 40px;
`

const EmailIntro = styled.div`
  ${typography.body.small}
  margin-top: 15px;

  @media (min-width: ${VW.desktop}) {
    ${typography.body.big}
  }
`

const EmailUs = styled.a`
  ${typography.body.small}
  color: ${colors.red};
  cursor: pointer;

  @media (min-width: ${VW.desktop}) {
    ${typography.body.big}
  }
`

export default QuestionsAndAnswers