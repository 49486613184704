import React, {
  useState,
  useRef,
  useCallback,
  useEffect
} from 'react'
import RichText from '../RichText'
import styled from 'styled-components'
import {
  typography,
  icons,
  colors,
  VW,
  font
} from '../../styles'

const Accordion  = ({
  title,
  content,
  type,
  initExpand: pInitExpand,
  noAccordion,
  addHeight
}) => {
  const description_ref = useRef()
  const [isOpened, setIsOpened] = useState(false)
  const [intiExpand, setInitExpand] = useState(pInitExpand)

  useEffect(() => {
    if(pInitExpand) {
      setInitExpand(false)
      setIsOpened(true)
    }
  }, [pInitExpand])

  const height = isOpened && description_ref.current ?
    description_ref.current.clientHeight : 0

  const handleClick = useCallback(() => {
    setIsOpened(!isOpened)
    if(addHeight) {
      addHeight(description_ref.current.clientHeight, !isOpened)
    }
  }, [isOpened])
  
  if(!title || !content) { return null }

  return (
    <Styled_Accordion
      isOpened={isOpened}
      noAccordion={noAccordion} >
      <Title onClick={!noAccordion && handleClick}>
        {type === 'box' && <span>Q</span>}
        <RichText json={title} /> 
        {!noAccordion && (
          <Svg_wrapper>
            {!isOpened ? (
              icons.increments()
            ) : (
              icons.minus()
            )}
          </Svg_wrapper>
        )}
      </Title>
      <Description_wrapper dynHeight={!intiExpand && height}>
        <div
          className='description_container'
          ref={description_ref} >
          {type === 'box' && <span>A</span>}
          <div className='accordion'>
            <RichText json={content} />
          </div>
        </div>
      </Description_wrapper>
    </Styled_Accordion>
  )
}

const Accordions = ({
  items: {
    json: items
  },
  type = 'normal',
  hasShowMore,
  isShowMore,
  expandFirst,
  noAccordion
}) => {
  const overflow_ref = useRef()
  const [overflowHeight, setOverflowHeight] = useState()
  let formatedBundles = []
  const bundledObject = {}

  useEffect(() => {
    if(!overflow_ref.current) { return }

    setOverflowHeight(overflow_ref.current.clientHeight)
  }, [isShowMore, overflow_ref])

  for (let i = 0; i < items.content.length + 1; i++) {
    const element = items.content[i];
    if(items.content.length === i) {
      formatedBundles.push({...bundledObject})

    } else if(element.nodeType === 'heading-2' || element.nodeType === 'heading-3') {
      if(Object.keys(bundledObject).length) {
        formatedBundles.push({...bundledObject})
      }

      bundledObject.title = element
      bundledObject.content = []

    } else if(bundledObject.content) {
      bundledObject.content.push(element)
    }
  }

  let overflowBundles = []
  const cloneFormatedBundle = [...formatedBundles]

  if(hasShowMore) {
    overflowBundles = cloneFormatedBundle.splice(5);
  }

  const handleAddHeight = useCallback((height, add) => {
    if(add) {
      setOverflowHeight((prevVal) => (prevVal + height))
    }
    else {
      setOverflowHeight((prevVal) => (prevVal - height))
    }
  }, [overflowHeight])
    
  
  return (
    <Styled_Accordions
      hasShowMore={hasShowMore}
      type={type} >
      {!hasShowMore ? (  
        <div>
          {formatedBundles.map((bundle, i) => (
            <Accordion
              key={`${bundle.title}-${i}-${i}`}
              title={bundle.title}
              content={{
                nodeType: 'document',
                content: bundle.content
              }}
              type={type}
              initExpand={expandFirst && i === 0 }
              noAccordion={noAccordion} />
            )
          )}
        </div>
      ) : (
        <div>
          {cloneFormatedBundle.map((bundle, i) => (
            <Accordion
              key={`${bundle.title}-${i}`}
              title={bundle.title}
              content={{
                nodeType: 'document',
                content: bundle.content
              }}
              type={type}
              initExpand={expandFirst && i === 0 }
              noAccordion={noAccordion} />
            )
          )}
          {overflowBundles && (
          <OverflowAccordions_wrapper
            isShowMore={isShowMore}
            overflowHeight={overflowHeight} >
            <OverflowAccordions_container ref={overflow_ref}>
              {overflowBundles.map((bundle, i) => (
                <Accordion
                  key={`${bundle.title}-${i}`}
                  title={bundle.title}
                  content={{
                    nodeType: 'document',
                    content: bundle.content
                  }}
                  type={type}
                  initExpand={expandFirst && i === 0 }
                  noAccordion={noAccordion}
                  addHeight={handleAddHeight} />
              ))}
            </OverflowAccordions_container>
          </OverflowAccordions_wrapper>
        )}
        </div>
      )}
    </Styled_Accordions>
  )
}

const spacing_normal = '6px'

const stylesType = {

  normal: {
    marginTop: spacing_normal,
    borderTop: '1px solid black',

    '[class*="Styled_Accordion"]': {
      padding: `13px 0`,
      borderBottom: '1px solid black'
    },

    '[class*="Title"], [class*="Description_wrapper"]': {
      ...typography.body.small
    },

    '[class*="Title"]': {
      fontWeight: 'bold'
    },

    '[class*="Description_wrapper"]': {
      'p': {
        paddingTop: `${spacing_normal}`
      }
    },

    '[class*="Svg_wrapper"]': {
      top: '7px'
    }
  },

  box: {
    '[class*="Styled_Accordion"]': {
      border: '1px solid black',
      padding: '20px 23px',

      '& + [class*="Styled_Accordion"]': {
        marginTop: '20px',
      },

      'a': {
        color: colors.red
      },

      [`@media (min-width: ${VW.desktop})`]: {
        padding: '24px 30px'
      }
    },

    '[class*="Title"], [class*="Description_wrapper"]': {
      ...typography.body.small,

      'span, h3, .accordion, .accordion p:first-child': {
        display: 'inline'
      },

      'span': {
        fontWeight: 'bold',
        marginRight: '15px'
      },

      [`@media (min-width: ${VW.desktop})`]: {
        ...typography.body.big
      }
    },

    '[class*="Description_wrapper"]': {
      'span': {
        color: colors.red
      }
    },

    '.description_container': {
      paddingTop: '30px'
    },

    '[class*="Svg_wrapper"]': {
      top: '7px',

      [`@media (min-width: ${VW.desktop})`]: {
        top: '9px'
      }
    }
  },

  chunky: {
    marginTop: spacing_normal,
    borderTop: '1px solid black',

    '[class*="Styled_Accordion"]': {
      borderBottom: '1px solid black',

      '& + div': {
        '[class*="Description_wrapper"]': {
          top: '-45px'
        }
      },

      '.description_container': {
        paddingBottom: '0'
      }
    },

    '[class*="Title"]': {
      ...typography.body.big,
      position: 'relative',
      padding: '20px 20px 20px 0',
      zIndex: '1',

      [`@media (min-width: ${VW.desktop})`]: {
        'h2, h3': {
          maxWidth: '170px'
        },
      },

      [`@media (min-width: ${VW.desktop}) and (max-width: 1200px)`]: {
        ...typography.body.regular
      }
    },

    '[class*="Description_wrapper"]': {
      ...typography.body.small,
      maxWidth: '360px',

      'p': {

        '&:empty': {
          marginTop: '20px'
        },

        'i': {
          display: 'inline-block',
          marginTop: '30px',
          color: colors.darkGrey,
          'fontFamily': font.typewriter,
          ...typography.body.tiny
        }
      },

      [`@media (min-width: ${VW.desktop})`]: {
        position: 'relative',
        left: '200px',
        top: '-65px',

        'p': {
          'i': {
            marginTop: '0'
          }
        }
      },

      [`@media (min-width: ${VW.desktop}) and (max-width: 1200px)`]: {
        ...typography.body.tiny,
        top: '-38px !important',
        maxWidth: '320px'
      }
    },

    '[class*="Svg_wrapper"]': {
      top: '29px',
      right: '20px'
    }
  },
}

const Styled_Accordions = styled.div`
  ${props => stylesType[props.type]}
  ${props => props.hasShowMore && `
    transition: max-height 0.3s ease;
  `}
`

const Styled_Accordion = styled.div`
  position: relative;
  ${props => props.noAccordion && `
    border-bottom: initial !important;

    [class*="Title"] {
      cursor: initial;
    }
  `}
`

const Title = styled.div`
  position: relative;
  cursor: pointer;
  padding-right: 20px;

`

const Description_wrapper = styled.div`
  max-height: ${props => props.dynHeight}px;
  overflow: hidden;
  transition: max-height 0.3s ease;
  
  .description_container {
    padding-bottom: 30px;
  }

  p { margin: 0 }
  i {
    ${typography.body.tiny}
    font-family: ${font.typewriter};
    color: ${colors.darkGrey};
  }
`

const Svg_wrapper = styled.div`
  position: absolute;
  right: 0;

  &,
  svg {
    height: 8px;
    width: 8px;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const OverflowAccordions_wrapper = styled.div`
  margin-top: 20px;
  overflow: hidden;
  max-height: ${props => props.isShowMore ? props.overflowHeight : '0'}px;
  transition: max-height 0.3s ease;
`

const OverflowAccordions_container = styled.div`
  
`

export default Accordions
