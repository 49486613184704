import React, {
  useState,
  useCallback,
  useEffect,
  useRef
} from 'react'
import styled from 'styled-components'
import  Dial from '../shared/Dial'
import { VW } from '../../styles'
import Button from '../Button'
import useProductForm from '../../hooks/useProductForm'
import VariantSelector from './VariantSelector'
import { useSelector } from 'react-redux'

const AddToCart = ({
  shopifyProduct: {
    handle,
    variants,
    options: pOptions
  }
}) => {
  const {
    AddProductToCart,
    addToCart,
    adding,
    loading,
    initialQuantity
  } = useProductForm({ handle })

  const id_ref = useRef()
  const [amountMap, setAmountMap] = useState(null)
  const [amount, setAmount] = useState(initialQuantity || 1)
  const [currentOptions, setCurrentOptions] = useState({})
  const [addCartTitle, setAddCartTitle] = useState('Add to cart')
  const [currentVariant, setCurrentVariant] = useState()

  const { lineItems } = useSelector(
    ({ storeSubdomain, checkouts }) => checkouts[storeSubdomain]
  )

  const updateCount = useCallback(newAmount => {
    setAmount(newAmount)
  })

  const findVariant = useCallback(({ edges = [] } = [], options, currentOptions) => {
    const allVariants = edges.map(v => v.node)
    if(!options || allVariants.length === 1) {
      if(allVariants.length === 1) {
        setAddCartTitle(`Add to cart $${allVariants[0].price}`)
        return allVariants[0]

      } else {
        setAddCartTitle('Add to cart')
        return
      }
    }

    const variant = allVariants.find(variant => {
      return variant.selectedOptions.filter(
        opt => currentOptions[opt.name] === opt.value
      ).length === options.length
    })

    if(variant) {
      setAddCartTitle(`Add to cart $${variant.price}`)
      return variant
      
    } else if(Object.keys(currentOptions).length === options.length) {
      setAddCartTitle('Unavailable Product')

    } else {
      setAddCartTitle('Add to cart')

    }

    return null
  }, [])

  const updateSelectedOption = useCallback(updatedOption => {
    const newCurrentOptions = {
      ...currentOptions,
      ...updatedOption
    }

    const getVariant = findVariant(variants, pOptions, newCurrentOptions)

    setCurrentVariant(getVariant)
    setCurrentOptions(newCurrentOptions)

    if(getVariant) {
      id_ref.current = getVariant.id
      const getAmount = amountMap[id_ref.current]
      setAmount(getAmount)
    }
  }, [amountMap, variants, currentOptions, pOptions])

  useEffect(() => {
    if(!initialQuantity) return
    setAmount(initialQuantity)
  }, [initialQuantity])

  useEffect(() => {
    setCurrentVariant(findVariant(variants, pOptions, currentOptions))

    if(variants.edges.length === 1 || !lineItems) return

    let variantMap = {}
    variants.edges.map(({ node: {
      id
    }}) => {
      variantMap[id] = 1
    })

    for (let i = 0; i < lineItems.edges.length; i++) {
      const item = lineItems.edges[i].node;
      if(!variantMap[item.variant.id]) continue

      variantMap[item.variant.id] = item.quantity
    }

    setAmountMap(variantMap)
    const getAmount = variantMap[id_ref.current] || 1
    setAmount(getAmount)
  }, [lineItems, pOptions, currentOptions])

  return (
    <Styled_AddToCart>
      {variants && variants.edges && variants.edges.length > 1 ? (
        <VariantSelectors>
          {pOptions.map(({
            name,
            values
          }) => (
            <VariantSelector
              key={`variant-${name}`}
              title={name}
              options={values || []}
              updateSelectedOption={updateSelectedOption} />
          ))}
          </VariantSelectors>
        ) : null}
      <Dial
        updateCount={updateCount}
        initVal={amount} />
      <Button
        title={addCartTitle}
        type='medium'
        color='black'
        reversedColor
        disabled={!currentVariant}
        triggerClick={() => AddProductToCart(amount, currentVariant && currentVariant.id)}
        loading={loading.toString()} >
        {loading ? `${adding}...` : addToCart}
      </Button>
    </Styled_AddToCart>
  )
}

const Styled_AddToCart = styled.div`
  margin-top: 20px;
  user-select: none;

  & > * {
    display: inline-block;
    vertical-align: top;
  }

  
  [class*="Styled_Dial"] { 
    width: 100px;
    margin-right: 20px;
  }

  [class*="ButtonStyled"] { 
    width: calc(100% - 120px);
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
    text-align: center;
  }

  @media (min-width: ${VW.desktop}) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
  
    [class*="Styled_Dial"] { 
      grid-column-start: 1;
      grid-column-end: 2;
      margin: 0;
      width: initial;
    }
  
    [class*="ButtonStyled"] {
      grid-column-start: 2;
      grid-column-end: 5;
      width: initial;
    }
  }
`

const VariantSelectors = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 4 span;
  margin-bottom: 15px;

  [class*="Selector"] {
    & + [class*="Selector"] {
      margin-top: 15px;
    }
  }
`

export default AddToCart