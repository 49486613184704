import React, {
  useCallback,
  useState
} from 'react'
import styled from 'styled-components'
import { colors, typography, VW } from '../../styles'
import RichText from '../RichText'

const WhatItDoes = ({
  tab: {
    iconsWithContents
  }
}) => {
  return (
    <WhatItDoes_wrapper>
      {iconsWithContents.map(({
        contentTitle,
        content: {
          json: content
        },
        icon: {
          fluid: {
            src: icon
          }
        }
      }) => (
        <Bullet_wrapper key={`WhatItDoes-${contentTitle}`}>
          <Bullet_container>
            <Title icon={icon}>
              {contentTitle}
            </Title>
            <Text>
              <RichText json={content} />
            </Text>
          </Bullet_container>
        </Bullet_wrapper>
      ))}
      
    </WhatItDoes_wrapper>
  )
}

const WhatItDoes_wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const Bullet_wrapper = styled.div`
  & + div {
    margin-top: 30px;
  }

  @media (min-width: ${VW.desktop}) {
    max-width: 300px;
    mrgin: 0;

    &:nth-child(1),
    &:nth-child(2) {
      margin: 0 0 70px;
    }
  }
  
  @media (min-width: ${VW.desktop}) and (max-width: 1200px) {
    max-width: 260px;
  }
`

const Bullet_container = styled.div``

const Title = styled.div`
  ${typography.body.big}
  position: relative;
  padding-left: 30px;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('${props => props.icon}');
  }

  @media (min-width: ${VW.desktop}) {
    ${typography.body.big}
  }
  
  @media (min-width: ${VW.desktop}) and (max-width: 1200px) {
    ${typography.body.regular}
  }
`

const Text = styled.div`
  ${typography.body.small}
  margin-top: 10px;
  color: ${colors.darkGrey};

  @media (min-width: ${VW.desktop}) {
    ${typography.body.small}
  }
  
  @media (min-width: ${VW.desktop}) and (max-width: 1200px) {
    ${typography.body.tiny}
  }

  p {
    margin: 0;
  }
`

export default WhatItDoes