import React, { useContext } from 'react'
import Image from './shared/Image'
import { Link } from 'gatsby'
import Price from './Price'
import { getPathname } from '../utils'
import { LayoutContext, DictionaryContext } from '../contexts'

import styled from 'styled-components'
import { VW, mobileVW, desktopVW, letterSpacing, colors, typography } from '../styles'
import RichText from './RichText'

const ProductThumbnail = (props) => {
  const {
    thumbnail: image,
    title,
    shopifyProduct,
    slug,
    thumbnailHover,
    absolute
  } = props
  const homepageDescription = props?.homepageDescription?.json || ''
  const { node_locale } = useContext(LayoutContext)
  const { shopNow } = useContext(DictionaryContext)

  return (
    <StyledProductThumbnail
      className={!absolute && 'active'}
      key={slug}
      to={getPathname(node_locale, slug)} >
      <StyledLink to={getPathname(node_locale, slug)}>
        <Wrapper className={!absolute && 'active'}>
          <Title className={!absolute && 'active'}>
            <h2>{title}</h2>
            {shopifyProduct && (
              <StyledPrice>
                <Price shopifyProduct={shopifyProduct} />
              </StyledPrice>
            )}
          </Title>
          <div className="productCard__shopnow">{shopNow}</div>
          <Description className="productCard__description">
            <RichText json={homepageDescription} />
          </Description>
        </Wrapper>
        <ImageWrapper className={`${!absolute && 'active'} image-wrapper`}>
          <div className='image-wrapper-container'>
            <HoverImage className={`hover_image`}>
              <StyledHover
                fluid={thumbnailHover?.fluid}
                alt={thumbnailHover?.title} />
            </HoverImage>
            <StyledImage
              className={!absolute && 'active'}
              fluid={image?.fluid}
              alt={title} />
          </div>
        </ImageWrapper>
      </StyledLink>
    </StyledProductThumbnail>
  )
}

ProductThumbnail.defaultProps = {
  absolute: true,
}

const StyledProductThumbnail = styled.div`
  position: relative;
  text-align: left;
  margin: 0 0 ${mobileVW(25)} 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;

  &.active {
    // display: block;
  }
  .productCard__shopnow {
    display: none;
  }
  @media (min-width: ${VW.desktop}) {
    margin: 0;
    height: 100%;

    &:hover {
      color: #FFF;

      .productCard__shopnow {
        height: 1.2em;
        opacity: 1;
        padding-top:10px;
      }
      .productCard__description {
        opacity:0;
      }
      .hover_image {
        opacity: 1;
        transition-duration: 0s;
      }
    }

    .productCard__shopnow {
      display: block;
      position: absolute;
      opacity:0;
      overflow: hidden;
      height:0;
      font-family: Basel-Web;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      color: #FFFFFF;
    }
  }
`

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${colors.popGray};
`

const ImageWrapper = styled.div`
  margin: 0;
  // position: relative;
  
  @media (min-width: ${VW.tablet}) {
    margin: 0;
  }
`

const StyledImage = styled(Image)`
  width: 100%;
  margin: 0 auto;

  img {
    object-fit: contain !important;
  }

  &.active {
    margin: 0 auto;
  }

  @media (min-width: ${VW.tablet}) {
    margin: 0;
  }
`

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  letter-spacing: ${letterSpacing(-0.1)};
  flex-grow:2;
  padding: 32px 25px 20px;
  
  &.active {
    position: relative;
  }
`

const Title = styled.div`
  line-height: 1.79;
  position: relative;

  h2 {
    line-height: 30px;
    border-bottom: 1px solid currentColor;
    padding-bottom: 3px;
    padding-right: 50px;
  }

  h2,
  span {
    ${typography.body.big}
  }
`

const StyledPrice = styled.span`
  position: absolute;
  top: 0;
  right: 0;
`

const Description = styled.div`
  ${typography.body.small}
  line-height: 20px;
  max-width: 360px;
  padding-right: 50px;
`

const HoverImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  z-index: 1;
`

const StyledHover = styled(Image)`
  height: 100%;
`

export default ProductThumbnail
