import React, { useRef } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styled from 'styled-components'
import { colors } from '../../styles'
import HeroCenterContent from '../hero/HeroCenterContent'
import { LayoutContext } from '../../contexts'

const HeroCarousel = ({ items }) => {
  const slick_ref = useRef();

  const settings = {
    dots: true,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    fade: true
  }

  const handleClick = (event) => {
    if(event.target.localName === 'a' || event.target.localName === 'button') { return }
    slick_ref.current.slickNext()
  }

  if(!items) { return <></> }
  
  return (
    <StyledCarousel
      onClick={(event) => handleClick(event)} >
      <Slider
        {...settings}
        ref={slick_ref} >
        {items.map(({
          centerText,
          centerButton,
          image,
          topLeftBigText,
          bottomRightBigText
        }, i) => (
          <div key={`carousel-item-${i}`}>
            {image.fluid ? (
              <StyledImage image={image.fluid.src} />
            ) : (
              <video
                autoPlay
                muted
                loop
                playsInline >
                <source
                  src={image.file.url}
                  type={image.file.contentType} />
              </video>
            )}
            {/* {topLeftBigText ||
              bottomRightBigText ||
              centerText ||
              centerButton && (
                
              )
            } */}
            <HeroCenterContent
              topLeftText={topLeftBigText}
              bottomRightText={bottomRightBigText}
              text={centerText && centerText.json}
              button={centerButton} />
          </div>
        ))}
      </Slider>
    </StyledCarousel>
  )
}

const StyledCarousel = styled.div`
  overflow: hidden;

  &,
  div {
    height: 100%;
  }

  .slick-arrow {
    display: none !important
  }

  .slick-slide {
    position: relative;
  }

  .slick-current {
    z-index: 1;
  }

  div .slick-dots {
    bottom: 40px;
    z-index: 1;

    li {
      margin: 0;

      button::before {
        color: white;
        opacity: 1;
        top: 1px;
      }
    }

    .slick-active {
      button::before {
        color: ${colors.red} !important;
        font-size: 12px;
      }
    }
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`

const StyledImage = styled.div`
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("${props => props.image}");
`

export default HeroCarousel