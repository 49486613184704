import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import {
  Certifications,
  Showcase,
  Review,
  CrossSell
} from '../components/product'
import MultiTabs from '../components/multiTabs/MultiTabs'
import Formulas from '../components/formulas/Formulas'
import PhotoStrip from '../components/PhotoStrip'
import QuestionsAndAnswers from '../components/QuestionsAndAnswers'

const trackProductView = data => {
  if(typeof window !== 'undefined' && data.contentfulProduct.shopifyProduct) {
    window.dataLayer.push({
      ecommerce: {
        detail: {
          products: [
            {
              name: data.contentfulProduct.title,
              id: data.contentfulProduct.shopifyProduct.handle,
              price:
                data.contentfulProduct.shopifyProduct.variants.edges[0].node
                  .price,
              brand: 'Isla',
              category: 'Skincare',
              variant: data.contentfulProduct.title,
            },
          ],
        },
      },
    })
    return false
  }
}

const Product = ({
  data,
  data: {
    contentfulProduct: {
      id: productId,
      slug,
      title,
      subtitle: {
        json: subtitle
      },
      productVolume,
      productSizes,
      seoTitle,
      seoDescription,
      accordions,
      shopifyProduct,
      carouselItems,
      productCertification,
      tickerImages: {
        image: tickerImages
      },
      tabsSection,
      questionsAndAnswers,
      moreProducts,
      formulasSection,
      reviewSection
    },
  },
}) => {
  useEffect(() => {
    trackProductView(data)
  })

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        slug={slug}
        image={carouselItems[0].image.fluid.src}
      />
      <Showcase
        title={title}
        subtitle={subtitle}
        volume={productVolume}
        size={productSizes}
        accordions={accordions}
        carouselItems={carouselItems}
        shopifyProduct={shopifyProduct} />
      {productCertification && <Certifications certification={productCertification} />}
      <PhotoStrip
        height={260}
        images={tickerImages.map((i) => ({
          src: i.resize.src,
          width: i.resize.width,
          height: i.resize.height,
          alt: i.description || ''
        }))} />
      {tabsSection && <MultiTabs tabsSection={tabsSection} />}
      {formulasSection && <Formulas formulas={formulasSection} />}
      {shopifyProduct && reviewSection && (
        <Review
          reviewSection={reviewSection}
          shopifyProduct={shopifyProduct} />
      )}
      {questionsAndAnswers && (
        <QuestionsAndAnswers
          data={questionsAndAnswers}
          hasShowMore />
      )}
      {moreProducts && <CrossSell moreProducts={moreProducts} />}
    </>
  )
}

// Accordion

export const PageQuery = graphql`
  query Product($id: String!) {
    contentfulProduct(id: { eq: $id }) {
      id
      title
      subtitle {
        json
      }
      slug
      template
      seoTitle
      seoDescription
      productVolume
      productSizes
      shopifyProduct {
        handle
        options {
          name
          values
        }
        variants {
          edges {
            node {
              id
              price
              priceV2 {
                amount
                currencyCode
              }
              selectedOptions {
                name
                value
              }
            }
          }
        }
      }
      accordions {
        json
      }
      carouselItems {
        image {
          title
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          file {
            url
            contentType
          }
        }
      }
      tickerImages {
        image {
          resize(height: 512) {
            src
            width
            height
          }
        }
      }
      tabsSection {
        image {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        video {
          file {
            url
            contentType
          }
        }
        tabs {
          ... on ContentfulTabWhatsIncluded {
            accordions {
              json
            }
            intro
            title
          }
          ... on ContentfulTabWhatItDoes {
            title
            intro
            iconsWithContents {
              content {
                json
              }
              contentTitle
              icon {
                fluid(maxWidth: 1600, quality: 80) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
          }
          ... on ContentfulTabHowToUseIt {
            intro
            title
            content {
              json
            }
          }
        }
      }
      reviewSection {
        quote {
          json
        }
        quoteInformation {
          json
        }
        rating
      }
      questionsAndAnswers {
        intro {
          json
        }
        qa {
          json
        }
        emailIntro
        emailUs
        image {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      formulasSection {
        intro {
          json
        }
        overview {
          json
        }
        professional {
          json
        }
        signature {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        tableDefinitions {
          json
        }
        tableSecondColumnHeader
        tableRow {
          secondColumn
          thirdColumn
        }
      }
      moreProducts {
        intro
        upSellText {
          json
        }
        listOfProducts {
          shopifyProduct {
            handle
            variants {
              edges {
                node {
                  price
                }
              }
            }
          }
          title
          homepageDescription {
            json
          }
          slug
          thumbnailHover {
            title
            fluid(maxWidth: 1600, quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          thumbnail {
            title
            fluid(maxWidth: 1600, quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

export default Product


// Temporary Removed this
// productCertification {
//   certificationIntro {
//     intro
//     content {
//       json
//     }
//     signature {
//       fluid(maxWidth: 1600, quality: 80) {
//         ...GatsbyContentfulFluid_withWebp_noBase64
//       }
//     }
//   }
//   bulletPoints {
//     richText {
//       json
//     }
//   }
// }

// tableFirstColumnHeader

// This one is from tableRow
// firstColumn