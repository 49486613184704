import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import {
  colors,
  font,
  sectionGrid,
  typography,
  VW,
  icons
} from '../../styles'
import RichText from '../RichText'
import FormulaTable from './FormulaTable'
import ItemCarousel from '../shared/ItemCarousel'

const Formulas = ({ formulas }) => {
  const [slickRef, setSlickRef] = useState()

  const updateSlickRef = useCallback((ref) => {
    setSlickRef(ref)
  }, [])

  const handlePrev = useCallback(() => {
    slickRef.slickPrev()
  }, [slickRef])

  const handleNext = useCallback(() => {
    slickRef.slickNext()
  }, [slickRef])

  return (
    <Formulas_wrapper>
      <Formulas_container>
        <ItemCarousel updateSlickRef={updateSlickRef} >
          {formulas.map(({
            intro: {
              json: intro
            },
            overview: {
              json: overview
            },
            professional,
            signature,
            ...tableProps
          }, i) => {

            return (
              <div>
                <Content key={`Formulas-${i}`}>
                  <Left_wrapper>
                    <Title>
                      <RichText json={intro} />
                    </Title>
                    <Overview>
                      <RichText json={overview} />
                    </Overview>
                    {(signature || professional) && (
                      <Signature_wrapper>
                        {signature && (
                          <Signature url={signature.fluid.src} />
                        )}
                        {professional && (
                          <Name>
                            <RichText json={professional.json} />
                          </Name>
                        )}
                      </Signature_wrapper>
                    )}
                    <CarouselButtons isCarousel={formulas.length > 1}>
                      <Prev onClick={handlePrev}>
                        {icons.right_arrow}
                      </Prev>
                      <Next onClick={handleNext}>
                        {icons.right_arrow}
                      </Next>
                    </CarouselButtons>
                  </Left_wrapper>
                  <Right_wrapper>
                    <FormulaTable
                      fomulasIndex={i}
                      data={tableProps} />
                  </Right_wrapper>
                </Content>
              </div>
            )
          })}
        </ItemCarousel>
      </Formulas_container>
    </Formulas_wrapper>
  )
}

const Formulas_wrapper = styled.div`
  padding: 30px 0 40px;
  background-color: ${colors.lightGrey};

  @media (min-width: ${VW.desktop}) {
    padding: 115px 0 125px;
  }
`

const Formulas_container = styled.div`
  ${sectionGrid}
  padding: 0 20px;
`

const Content = styled.div`

  @media (min-width: 860px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 20px;
  }
`

const Left_wrapper = styled.div`

  @media (min-width: 860px) {
    grid-column-start: 1;
    grid-column-end: 6;
  }

  @media (min-width: ${VW.desktop}) {
    grid-column-start: 1;
    grid-column-end: 4;
  }
`

const Title = styled.div`
  ${typography.headlines.regular}
  margin-bottom: 15px;
  
  p { margin: 0 }

  @media (min-width: ${VW.desktop}) {
    margin-bottom: 30px;
  }
`

const Overview = styled.div`
  ${typography.body.big}
  color: ${colors.darkGrey};
  margin-bottom: 15px;

  p { margin: 0 }

  @media (min-width: ${VW.desktop}) {
    margin-bottom: 60px;
  }
`

const Signature_wrapper = styled.div`
  margin-bottom: 60px;
`

const Signature = styled.div`
  width: 72px;
  height: 56px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('${props => props.url}');
`

const Name = styled.div`
  ${typography.body.tiny}
  font-family: ${font.typewriter};
  color: ${colors.darkGrey};
  margin-top: 2px;

  p { margin: 0 }
`

const Right_wrapper = styled.div`
  max-width: 600px;

  @media (min-width: 860px) {
    grid-column-start: 7;
    grid-column-end: 13;
    max-width: initial;
  }

  @media (min-width: ${VW.desktop}) {
    grid-column-start: 5;
    grid-column-end: 13;
  }
`

const CarouselButtons = styled.div`
  display: ${props => props.isCarousel ? 'flex' : 'none'};
  justify-content: space-between;
  width: 81px;
  margin-bottom: 30px;

  div {
    position: relative;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-color: ${colors.red};
    cursor: pointer;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 15px;
      height: 15px;
    }
  }

  @media (min-width: 860px) {
    width: 130px;
    
    div {
      width: 55px;
      height: 55px;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`

const Next = styled.div`
  svg {
    transform: translate(-50%, -50%);
  }
`

const Prev = styled.div`
  svg {
    transform: translate(-50%, -50%) rotate(180deg);
  }
`


export default Formulas