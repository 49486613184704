import React, { useState } from 'react'
import styled from 'styled-components'
import {
  colors,
  font,
  sectionGrid,
  typography,
  VW
} from '../../styles'
import RichText from '../RichText'

const PillarRow = ({
  row: {
    firstColumn,
    secondColumn,
    thirdColumn
  }
}) => {

  return (
    <PillarRow_wrapper>
      {firstColumn && (
        <FirstColumn className='column-1'>
          {firstColumn}
        </FirstColumn>
      )}
      <SecondColumn className='column-2'>
        {secondColumn}
      </SecondColumn>
      <ThirdColumn>{thirdColumn}</ThirdColumn>
    </PillarRow_wrapper>
  )
}

const PillarRow_wrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${colors.mediumGrey};
  padding: 10px 0;
  
  p {
    margin: 0;
  }
`

const FirstColumn = styled.p`
  min-width: 100px;
`

const SecondColumn = styled.p`
  width: 100%;
`

const ThirdColumn = styled.p`
  width: 50px;
  text-align: right;
  color: ${colors.red};
`

export default PillarRow