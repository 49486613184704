import React from 'react'
import styled from 'styled-components'
import {
  colors,
  font,
  sectionGrid,
  typography,
  icons,
  VW
} from '../../styles'
import RichText from '../RichText'

const Certifications = ({
  certification: {
    certificationIntro: {
      intro,
      content: {
        json: content
      },
      signature: {
        fluid: signature
      }
    },
    bulletPoints: {
      richText: {
        json: bulletPoints
      }
    },
  }
}) => {
  return (
    <Certifications_wrapper>
      <Certifications_container>
        <Intro>{intro}</Intro>
        <Content>
          <Text>
            <RichText json={content} />
          </Text>
          <Image image={signature.src} />
        </Content>
        <BulletPoints>
          {bulletPoints.content.map((point, i) => (
            <Point key={`bulletPoints-certification-${i}`}>
              <RichText json={point} />
              {icons.check_mark}
            </Point>
          ))}
        </BulletPoints>
      </Certifications_container>
    </Certifications_wrapper>
  )
}

const Certifications_wrapper = styled.div`
  background-color: ${colors.lightGrey};
  padding: 20px 0 30px;
  margin-top: 5px;

  @media (min-width: ${VW.desktop}) {
    padding: 40px 0 60px;
  }
`

const Certifications_container = styled.div`
  display: block;
  padding: 0 20px;
  font-size: ${typography.body.tiny}
  font-family: ${font.typewriter};

  p {
    margin: 0;
  }

  @media (min-width: ${VW.desktop}) {
    ${sectionGrid}
    column-gap: 20px;
  }
`

const Intro = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  color: ${colors.red};
  margin-bottom: 25px;

  @media (min-width: ${VW.desktop}) {
    margin: 0;
  }
`

const Content = styled.div`
  grid-column-start: 5;
  grid-column-end: 8;
  line-height: 18px;
`

const Text = styled.div``

const Image = styled.div`
  width: 55px;
  height: 43px;
  margin-top: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('${props => props.image}');
`

const BulletPoints = styled.div`
  grid-column-start: 9;
  grid-column-end: 13;
  margin-top: 35px;

  @media (min-width: ${VW.desktop}) {
    margin: 0;
  }
`

const Point = styled.div`
  position: relative;
  width: initial;
  display: block;
  margin: 0 0 15px 20px;  

  svg {
    position: absolute;
    top: 2px;
    right: calc(100% + 9px);
    height: 10px;
    width: 10px;
  }

  @media (min-width: ${VW.desktop}) {
    display: inline-block;
    width: calc(50% - 20px);
    vertical-align: top;
    margin-left: 0;

    &:nth-child(2n) {
      padding-left: 40px;
  
      svg {
        right: calc(100% + 9px - 40px);
      }
    }
  }
`

export default Certifications
