
import React from 'react'
import styled from 'styled-components'
import {
  colors,
  sectionGrid,
  typography,
  VW,
  thumbnailImage
} from '../../styles'
import ProductThumbnail from '../ProductThumbnail'
import RichText from '../RichText'

const CrossSell = ({
  moreProducts: {
    intro,
    upSellText: {
      json: upSellText
    },
    listOfProducts
  }
}) => {
  return (
    <CrossSell_wrapper>
      <CrossSell_container>
        <Content>
          <Intro>{intro}</Intro>
          <Text>
            <RichText json={upSellText} />
          </Text>
        </Content>
        {listOfProducts.map((product, i) => (
          <CrossSell_product_wrapper key={`Complete-Regimen-${i}`}>
            <ProductThumbnail {...product}/>
          </CrossSell_product_wrapper>
        ))}
      </CrossSell_container>
    </CrossSell_wrapper>
  )
}

const CrossSell_wrapper = styled.div`
  border-top: 2px solid black;
  padding: 35px 0 20px;

  @media (min-width: ${VW.desktop}) {
    padding: 60px 0 100px;
  }
`

const CrossSell_container = styled.div`
  display: block;
  padding: 0 20px;
  max-width: 600px;
  margin: auto;

  @media (min-width: ${VW.desktop}) {
    ${sectionGrid}
    column-gap: 20px;  
  }
`

const CrossSell_product_wrapper = styled.div`
  grid-column-start: 5;
  grid-column-end: 9;

  & + div {
    grid-column-start: 9;
    grid-column-end: 13;
  }

  & > div {
    margin: 0;
  }

  & + div > div  {
    margin: 20px 0 0;
  }

  img {
    ${thumbnailImage}
  }

  @media (min-width: ${VW.desktop}) {
    & > div {
      margin: 0 !important;
    }
  }
`

const Content = styled.div`
  grid-column-start: 1;
  grid-column-end: 4;
  margin-bottom: 40px;

  @media (min-width: ${VW.desktop}) {
    margin: 0;
  }
`

const Intro = styled.div`
  ${typography.headlines.regular}

  @media (min-width: ${VW.desktop}) {
    ${typography.headlines.articleAlternate}
  }
`

const Text = styled.div`
  ${typography.body.big}
  margin-top: 10px;

  p { margin: 0 }

  a { color: ${colors.red} }

  @media (min-width: ${VW.desktop}) {
    margin-top: 25px;
    max-width: 250px;
  }
`

export default CrossSell