import React, {
  useCallback,
  useState
} from 'react'
import styled from 'styled-components'
import {
  colors,
  sectionGrid,
  typography,
  VW
} from '../../styles'
import Image from '../shared/Image'
import HowToUseIt from './HowToUseIt'
import WhatItDoes from './WhatItDoes'
import WhatsIncluded from './WhatsIncluded'

const MultiTabs = ({
  tabsSection: [{
    tabs,
    video,
    image: {
      fluid: image
    }
  }]
}) => {
  const [tabSelected, setTabSelected] = useState(0)

  const handleTabChange = useCallback((index) => {
    if(tabSelected !== index) {
      setTabSelected(index)
    }
  }, [tabSelected])

  return (
    <MultiTabs_wrapper>
      <MultiTabs_container>
        <Tabs_wrapper>
          <Tabs_container>
            <Titles>
              {tabs.map(({ intro }, i) => (
                <Title
                  key={`tabs-title-${i}`}
                  isSelected={tabSelected === i}
                  onClick={() => handleTabChange(i)}>
                  {intro}
                </Title>
              ))}
            </Titles>
            <Content_wrapper>
              <Content_container>
                {tabs.map((tab, i) => {
                  const addedProps_parent = {
                    key: `tab-${i}`,
                    className: tabSelected === i ? 'tab show' : 'tab'
                  }
                  const addedProps_child = {
                    tab,
                    position: i,
                  }

                  const isFirst = tabSelected === i

                  if(tab.__typename === 'ContentfulTabHowToUseIt') {
                    return (
                      <div {...addedProps_parent}>
                        <Mobile_Title>{tab.intro}</Mobile_Title>
                        {isFirst ? (
                          <Mobile_Video
                            autoPlay
                            muted
                            playsInline
                            loop >
                            <source
                              src={video.file.url}
                              type='video/quicktime' />
                            <source
                            src={video.file.url}
                            type='video/webm' />
                          </Mobile_Video>
                        ) : (
                          <Mobile_Image fluid={image} />
                        )}
                        <HowToUseIt {...addedProps_child} />
                      </div>
                    )

                  } else if(tab.__typename === 'ContentfulTabWhatItDoes') {
                    return (
                      <div {...addedProps_parent}>
                        <Mobile_Title>{tab.intro}</Mobile_Title>
                        {isFirst ? (
                          <Mobile_Video
                            autoPlay
                            playsInline
                            muted
                            loop >
                            <source
                              src={video.file.url}
                              type='video/quicktime' />
                            <source
                            src={video.file.url}
                            type='video/webm' />
                          </Mobile_Video>
                        ) : (
                          <Mobile_Image fluid={image} />
                        )}
                        <WhatItDoes {...addedProps_child} />
                      </div>
                    )

                  } else if(tab.__typename === 'ContentfulTabWhatsIncluded') {
                    return (
                      <div {...addedProps_parent}>
                        <Mobile_Title>{tab.intro}</Mobile_Title>
                        {isFirst ? (
                          <Mobile_Video
                            autoPlay
                            playsInline
                            muted
                            loop >
                            <source
                              src={video.file.url}
                              type='video/quicktime' />
                            <source
                            src={video.file.url}
                            type='video/webm' />
                          </Mobile_Video>
                        ) : (
                          <Mobile_Image fluid={image} />
                        )}
                        <WhatsIncluded {...addedProps_child} />
                      </div>
                    )
                  }

                  return null
                })}
              </Content_container>
            </Content_wrapper>
          </Tabs_container>
        </Tabs_wrapper>
        <Media_wrapper>
          <Media_container>
            <Styled_Image fluid={image} />
            <Video show={tabSelected === 0}>
              <video
                autoPlay
                muted
                loop
                playsInline >
                <source
                  src={video.file.url}
                  type='video/webm' />
              </video>
            </Video>
          </Media_container>
        </Media_wrapper>
      </MultiTabs_container>
    </MultiTabs_wrapper>
  )
}

const MultiTabs_wrapper = styled.div``

const MultiTabs_container = styled.div`
  ${sectionGrid}
  padding: 0 20px;
  column-gap: 20px;
  margin-bottom: 100px;
`

const Tabs_wrapper = styled.div`
  padding-top: 20px;

  @media (min-width: ${VW.desktop}) {
    padding-top: 160px;
    grid-column-start: 1;
    grid-column-end: 8;
    margin-bottom: 70px;
  }
`

const Tabs_container = styled.div``

const Titles = styled.div`

`

const Title = styled.p`
  display: none;
  vertical-align: top;
  cursor: pointer;
  margin: 0;
  color: ${colors.darkGrey};
  ${props => props.isSelected && `
    color: black;
    border-bottom: 2px solid black;
  `}

  &:first-child {
    margin-right: 20px;
  }
  
  @media (min-width: ${VW.desktop}) {
    ${typography.headlines.articleAlternate}
    display: inline-block;
  }
  
  @media (min-width: ${VW.desktop}) and (max-width: 1200px) {
    ${typography.headlines.regular}
  }
`

const Content_wrapper = styled.div`
  .tab {
    transition: opacity 0.3s ease;

    & + .tab {
      margin-top: 70px;
    }

    @media (min-width: ${VW.desktop}) {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      pointer-events: none;
      margin: 0;

      &.show {
        position: relative;
        opacity: 1;
        pointer-events: initial;
      }
    }
  }
`

const Content_container = styled.div`
  position: relative;

  @media (min-width: ${VW.desktop}) {
    margin-top: 50px;
  }
`

const Media_wrapper = styled.div`
  display: none;
  grid-column-start: 9;
  grid-column-end: 13;
  margin-top: 65px;
  width: calc(100% + 20px);

  @media (min-width: ${VW.desktop}) {
    display: block;
    margin-bottom: 70px;
  }
`

const Media_container = styled.div`
  position: relative;
`

const Styled_Image = styled(Image)``

const Video = styled.div`
  position: absolute;
  top: 100px;
  left: -120px;
  height: 100%;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  overflow: hidden;

  ${props => props.show && `
    opacity: 1;
    pointer-events: initial;
  `}

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
  }

  @media (min-width: ${VW.desktop}) and (max-width: 1200px) {
    width: 364px;
  }
`

const Mobile_Title = styled.p`
  ${typography.headlines.regular}
  margin: 10px 0;

  p {
    margin: 0;
  }

  @media (min-width: ${VW.desktop}) {
    display: none;
  }
`

const Mobile_Video = styled.video`
  max-width: 100%;
  max-height: 80vh;
  margin-bottom: 15px;

  @media (min-width: ${VW.desktop}) {
    display: none;
  }
`

const Mobile_Image = styled(Image)`
  max-width: 640px;
  max-height: 80vh;
  margin-bottom: 30px;

  @media (min-width: ${VW.desktop}) {
    display: none;
  }
`


export default MultiTabs