import React, { useRef, useState, useCallback } from 'react'
import styled from 'styled-components'
import {
  colors,
  font,
  sectionGrid,
  typography,
  VW
} from '../../styles'
import Button from '../Button'
import RichText from '../RichText'
import PillarRow from './PillarRow'

const initialBodyHeight = 350

const FormulaTable = ({
  fomulasIndex,
  data: {
    tableDefinitions: {
      json: tableDefinitions
    },
    tableFirstColumnHeader,
    tableSecondColumnHeader,
    tableRow
  }
}) => {
  const tableBody_ref = useRef()
  const half = Math.ceil(tableRow.length / 2)

  const [firstHalf, _] = useState(tableRow.splice(0, half))
  const [secondHalf, __] = useState(tableRow.splice(-half))
  const [bodyHeight, setBodyHeight] = useState(initialBodyHeight)

  const handleLoadMore = useCallback(() => {
    if(bodyHeight === initialBodyHeight) {
      setBodyHeight(tableBody_ref.current.clientHeight)
    } else {
      setBodyHeight(350)
    }
  }, [tableBody_ref, bodyHeight])

  return (
    <FormulaTable_wrapper>
      <FormulaTable_container>
        <Table_header>
          <Table_header_columns>
            {tableFirstColumnHeader && (
              <div className="column-1">
                {tableFirstColumnHeader}
              </div>
            )}
            <div className="column-2">
              {tableSecondColumnHeader}
            </div>
          </Table_header_columns>
          <Table_header_definitions>
            <RichText json={tableDefinitions} />
          </Table_header_definitions>
        </Table_header>
        <Table_body bodyHeight={bodyHeight}>
          <div
            className="body-wrapper"
            ref={tableBody_ref} >
            <Pillar>
              {firstHalf.map((row, i) => (
                <PillarRow
                  key={`firstPillar-${fomulasIndex}-${i}`}
                  row={row} />
              ))}
            </Pillar>
            <Pillar>
              {secondHalf.map((row, i) => (
                <PillarRow
                  key={`secondPillar-${fomulasIndex}-${i}`}
                  row={row} />
              ))}
            </Pillar>
          </div>
        </Table_body>
        <LoadMore>
          <Button
            title={bodyHeight === initialBodyHeight ? 'Load All' : 'Show Less'}
            type='small'
            color='red'
            triggerClick={handleLoadMore} />
        </LoadMore>
      </FormulaTable_container>
    </FormulaTable_wrapper>
  )
}

const FormulaTable_wrapper = styled.div``

const FormulaTable_container = styled.div`
  ${typography.body.tiny}

  .column-1 {
    width: 100px;
  }

  .column-2 {

  }
`

const Table_header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid ${colors.mediumGrey};
  padding-bottom: 10px;

  @media (min-width: ${VW.desktop}) {
    border-color: black;
    padding-bottom: 8px;
  }
`

const Table_header_columns = styled.div`
  & > div {
    display: inline-block;
    vertical-align: top:
  }
`
const Table_header_definitions = styled.div`
  width: 105px;
  text-align: right;

  p {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    color: ${colors.red};

    + p {
      margin: 3px 0 0;
    }
  }

  @media (min-width: 860px) {
    width: initial;
    text-align: initial;

    p + p {
      margin: 0 0 0 15px;
    }
  }
`

const Table_body = styled.div`
  display: block;
  max-height: ${props => props.bodyHeight}px;
  overflow: hidden;
  transition: max-height 0.3s ease;
  
  @media (min-width: ${VW.desktop}) {
    max-height: initial;
    overflow: initial;

    .body-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
  }
`

const Pillar = styled.div`
  width: 100%;

  @media (min-width: ${VW.desktop}) { 
    width: calc(50% - 35px);
  }
`

const LoadMore = styled.div`
  display: table;
  margin: 40px auto 0;

  @media (min-width: ${VW.desktop}) { 
    display: none;
    margin: 0;
  }
`

export default FormulaTable